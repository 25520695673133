var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._v(" Negative keyword lists - summary by account ")])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.adNetworks,"label":"Ad network","clearable":"","prepend-icon":"stream"},model:{value:(_vm.selectedAdNetwork),callback:function ($$v) {_vm.selectedAdNetwork=$$v},expression:"selectedAdNetwork"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.verticals,"item-text":"name","item-value":"id","multiple":"","label":"Verticals","clearable":"","prepend-icon":"construction"},model:{value:(_vm.selectedVerticals),callback:function ($$v) {_vm.selectedVerticals=$$v},expression:"selectedVerticals"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Account name","clearable":"","prepend-icon":"text_snippet"},model:{value:(_vm.accountNameSearch),callback:function ($$v) {_vm.accountNameSearch=$$v},expression:"accountNameSearch"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.show}},[_vm._v("Show")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems,"item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"header.lists_near_keyword_limit_count",fn:function(ref){
    var header = ref.header;
return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("help")])]}}],null,true)},[_c('span',[_vm._v("The number of lists containing more than 4800 keywords")])]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.unlinked_count",fn:function(ref){
    var header = ref.header;
return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("help")])]}}],null,true)},[_c('span',[_vm._v("The number of lists not linked to any campaigns")])]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }