<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Negative keyword lists - summary by account
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedAdNetwork" :items="adNetworks" label="Ad network" clearable prepend-icon="stream">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedVerticals" :items="verticals" item-text="name" item-value="id" multiple label="Verticals" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-text-field v-model="accountNameSearch" label="Account name" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>
          
          <v-col>
            <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      :item-class="itemRowBackground"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`header.lists_near_keyword_limit_count`]="{ header }">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">help</v-icon>
          </template>
          <span>The number of lists containing more than 4800 keywords</span>
        </v-tooltip>
        {{ header.text }}
      </template>

      <template v-slot:[`header.unlinked_count`]="{ header }">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">help</v-icon>
          </template>
          <span>The number of lists not linked to any campaigns</span>
        </v-tooltip>
        {{ header.text }}
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NegativeKwListsTable',

  data () {
    return {
      headers: [
        { text: 'Ad network', value: 'ad_network' },
        { text: 'Account ID', value: 'account_id' },
        { text: 'Account name', value: 'account_name' },
        { text: 'Account number', value: 'account_number' },
        { text: 'Vertical', value: 'vertical' },
        { text: '# NKW lists', value: 'nkw_list_count' },
        { text: '# Brand auto', value: 'brand_automated_list_count' },
        { text: '# Comp auto', value: 'competitor_automated_list_count' },
        { text: '# Perf auto', value: 'performance_automated_list_count'},
        { text: '# large lists', value: 'lists_near_keyword_limit_count'},
        { text: '# unlinked', value: 'unlinked_count'},
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'negative_kw_lists_options',
      lsFilters: 'negative_kw_lists_filters',
      endpoint: '/a/nkw/list-account-overview',
      selectedAdNetwork: null,
      selectedVerticals: [],
      accountNameSearch: '',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    adNetworks () {
      return this.$store.state.core.adNetworks
    },

    verticals () {
      return this.$store.state.core.verticals
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchNegativeKwData()
      },
      deep: true,
    },
  },

  methods: {
    fetchNegativeKwData: function () {
      let myURL = this.endpoint
      this.loading = true

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL + '?', this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter ad network
      if (this.selectedAdNetwork) {
        myURL += '&ad_network=' + this.selectedAdNetwork
      }

      // filter verticals
      if (this.selectedVerticals.length > 0) {
        myURL += '&verticals_fk=' + this.selectedVerticals.join('|')
      }

      // filter account name search
      if (this.accountNameSearch) {
        myURL += '&account_name=~' + this.accountNameSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    itemRowBackground: function (item) {
      if (item.lists_near_keyword_limit_count > 0) {
        return 'yellow lighten-4'
      }

      if (item.brand_automated_list_count !== 1 || item.performance_automated_list_count !== 1) {
        return 'orange lighten-3'
      }

      return 'white'
    },

    show: function () {
     let filterObj = {
        'selectedAdNetwork': this.selectedAdNetwork,
        'selectedVerticals': this.selectedVerticals,
        'accountNameSearch': this.accountNameSearch
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchNegativeKwData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchNegativeKwData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAdNetwork']) { this.selectedAdNetwork = filters['selectedAdNetwork'] }
      if (filters['selectedVerticals']) { this.selectedVerticals = filters['selectedVerticals'] }
      if (filters['accountNameSearch']) { this.accountNameSearch = filters['accountNameSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
